/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <PTabView :activeIndex.sync="activeTab" class="mt-5">
          <PTabPanel header="Lignes budgétaires">
            <LigneBudgetaireList />
          </PTabPanel>
          <PTabPanel header="Ordres de mission">
            <OrdreMissionList />
          </PTabPanel>
          <PTabPanel header="Missions">
            <MissionList />
          </PTabPanel>
          <PTabPanel header="Missionnaires">
            <MissionnaireList />
          </PTabPanel>
        </PTabView>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { paginatorMixin } from '@/mixins/paginator-mixin'
import { authMixin } from '@/mixins/auth-mixin'

import OrdreMissionList from './OrdreMissionList.vue'
import MissionnaireList from './MissionnaireList.vue'
import MissionList from './MissionList.vue'
import LigneBudgetaireList from './LigneBudgetaireList.vue'

export default {
  props: {},
  components: {
    OrdreMissionList,
    MissionList,
    MissionnaireList,
    LigneBudgetaireList
},
  mixins: [ paginatorMixin, authMixin ],
  data () {
    return {
      activeTab: 0
    }
  },
  created () {
  },
  watch: {
  },
  computed: {
    ...mapGetters({
    }),

  },
  methods: {
    ...mapActions({
    }),
  }
}
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>