<template>
  <div>
    <PDialog
      :header="`${title} -${titleExtra}`"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'visible' }"
      :containerStyle="{ width: '30vw' }"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <template>
              <div class="row p-2">
                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Reference SEMO</div>
                    <input
                      type="number"
                      id="reference"
                      class="form-control"
                      name="reference"
                      v-model="editableItem.reference"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Prime par nuité</div>
                    <input
                      type="number"
                      id="primeParNuite"
                      class="form-control"
                      name="primeParNuite"
                      v-model="editableItem.primeParNuite"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>

                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Nombre de nuité</div>
                    <input
                      type="number"
                      id="nombreNuite"
                      class="form-control"
                      name="nombreNuite"
                      v-model="editableItem.nombreNuite"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Prime par repas</div>
                    <input
                      type="number"
                      id="primeParRepas"
                      class="form-control"
                      name="primeParRepas"
                      v-model="editableItem.primeParRepas"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Nombre de repas</div>
                    <input
                      type="number"
                      id="nombreRepas"
                      class="form-control"
                      name="nombreRepas"
                      v-model="editableItem.nombreRepas"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 field">
                  <div class="form-floating mb-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label for="libelle">Pièce justificative</label>
                      <div>
                        <FilePondUploader
                          ref="fileUploader"
                          class="col-12 md:col-12"
                          v-model="editableItem.pieces"
                          @save="fileSaved"
                          :autoResize="true"
                          rows="8"
                        />
                      </div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </template>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";
import AsyncSearchInput from "../../../../common/AsyncSearchInput.vue";
import FilePondUploader from "../../../../uploader/FilePondUploader.vue";

export default {
  components: {
    AsyncSearchInput,
    FilePondUploader,
  },
  props: {
    title: {
      type: String,
      default: "Ajouter une ligne budgétaire",
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    hidePlacementField: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      editableItem: {},
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0,
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
  },
  created() {},
  computed: {
    titleExtra() {
      return this.editableItem.mission != null
        ? `${this.editableItem.mission.objetMission} |
          ${this.editableItem.missionnaire.nomPrenom}`
        : "";
    },
  },
  methods: {
    ...mapActions({}),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        // dossiers: this.selectedDemandeurs
      });
      this.hide();
      this.editableItem = {};
    },
  },
};
</script>
